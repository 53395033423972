import React from "react";
import styled from "styled-components";

import item1 from '../assets/images/item1.png'
import item2 from '../assets/images/item2.png'
import item3 from '../assets/images/item3.png'
import item4 from '../assets/images/item4.png'
import item5 from '../assets/images/item5.png'
import item6 from '../assets/images/item6.png'
import item7 from '../assets/images/item7.png'
import item8 from '../assets/images/item8.png'
import item9 from '../assets/images/item9.png'

const StyledProductSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 295vh;
  background-color: #2d2c2b;
  color: #fefefe;
  padding: 0 64px;

  @media only screen and (max-width: 600px) {
    height: 100%;
    padding: 0 16px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  align-items: center;
  margin: 20px 0;
  text-align: justify;

  &:last-child {
    margin: 0;
  }
`

const SectionTitle = styled.h2`
  font: 48px 'Mont Bold';

  @media only screen and (max-width: 600px) {
    font-size: 38px;
    text-align: center;
  }
`

const Text = styled.p`
  font-size: 18px;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`

const WhatsappButton = styled.a`
  background-color: #215bea;
  text-decoration: none;
  color: #fefefe;
  padding: 16px 32px;
  border-radius: 26px;
  cursor: pointer;
  transition: 500ms !important;


  :hover {
    background-color: #2256db;
    color: #d9d8d6;
  }
`

const ProductItem = styled.img`
  width: 32%;
  height: 32%;
  margin: 8px;
  border-radius: 16px;
  cursor: pointer;
  transition: 500ms !important;

  @media only screen and (max-width: 600px) {
    width: 98%;
    height: 16%;
  }

  :hover {
    box-shadow: 0px 10px 32px -4px #fefefe;
  }
`

const SeeMore = styled.a`
  text-decoration: none;
  color: #fefefe;
  font-size: 18px;
  transition: 300ms;
  margin-top: 20%;

  :hover {
    color: #d9d8d6;
  }
`

function ProductsSection () {
  const items = [
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    item9,
  ]

  return (
    <StyledProductSection id='products'>
      <SectionTitle>Nossos produtos</SectionTitle>
      <Container data-aos="fade-left">
        <Text>
         Temos mais de 3000 produtos em nosso catálogo.
        </Text>
      </Container>
      <Container>
      <WhatsappButton 
        href="https://api.whatsapp.com/send?phone=5524992980146" 
        target='_blank'
        data-aos="zoom-in"
      >
        Comprar
      </WhatsappButton>
      </Container>

      <Container>
        {items.map(item => (
        <>
          <ProductItem src={item} data-aos="zoom-in"/>
        </>
        ))}
      </Container>

      <Container>
        <SeeMore href="http://instagram.com/puccinienxovais" target="_blank">Ver mais</SeeMore>
      </Container>

    </StyledProductSection>

  )

}

export {ProductsSection}
export default ProductsSection;