import React from 'react'
import styled from 'styled-components'
import { Link, animateScroll as scroll } from "react-scroll";

import logo from '../assets/images/logo-azul-transp.png'

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 2;
  height: 10vh;
  background-color: #2d2b2c;
  display: flex;
  justify-content: space-between;
  padding: 0 64px;

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  margin: 8px;
  color: #fefefe;
  cursor: pointer;
  text-decoration: none;
  
  :hover {
    color: #d9d8d6;
  }
`

function Navbar () {
  return (
    <StyledNav>
      <Container >
        <a href='/'>
          <img src={logo}  alt='Logo da puccini enxovais' height='56px' width='56px'/>
        </a>
      </Container>
        <Container>
          <StyledLink to='home' offset={-45}>
            Home
          </StyledLink>
          <StyledLink to='about' offset={-45}>
            Sobre
          </StyledLink>
          <StyledLink to='products' offset={-45}>
            Produtos
          </StyledLink>
          <StyledLink to='contato' offset={-45}>
            Contato
          </StyledLink>
        </Container>
    </StyledNav>
  )
}

export { Navbar }
export default Navbar
