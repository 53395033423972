import React, {useEffect} from "react";
import styled from "styled-components";
import AOS from "aos";

import "aos/dist/aos.css"

import {ReactComponent as TableRestaurant} from "../assets/icons/table-restaurant.svg";
import {ReactComponent as KingBed} from "../assets/icons/king-bed.svg";
import {ReactComponent as Bathtub} from "../assets/icons/bathtub.svg";



const StyledAboutSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  background-color: #215bea;
  color: #fefefe;
  padding: 0 64px;

  @media only screen and (max-width: 600px) {
    height: 100vh;
    padding: 0 16px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  text-align: justify;

  &:last-child {
    margin: 0;
  }
`

const SectionTitle = styled.h2`
  font: 48px 'Mont Bold';

  @media only screen and (max-width: 600px) {
    font-size: 38px;
    text-align: center;
  }
`

const Text = styled.p`
  font-size: 18px;
`

const TextBig = styled.p`
  font-size: 48px;
  color: #2d2c2b;
  text-align: center;
`

function AboutSection () {

  useEffect(() => {
    AOS.init({duration: 1000})
  },[])

  return (
    <StyledAboutSection id="about">
      <SectionTitle>Nos conhecendo melhor</SectionTitle>
      <Container data-aos="flip-up">
        <KingBed/>
        <TableRestaurant/>
        <Bathtub/>
      </Container>
      <Container data-aos="fade-left">
        <Text>
          Há mais de 20 anos temos construindo nossa história no estado do Rio de Janeiro, agora seguimos em expansão para os demais estados do Brasil.
          Aqui você encontra tudo em artigos de cama, mesa, banho e utilidades para lar.
          Somente uma marca com toda essa bagagem consegue oferecer condições  tão especiais:
          Parcelamos em até 4x, com vantagens para pagamentos a vista, e o frete ainda pode ser grátis a depender da sua localização.
        </Text>
      </Container>
      <Container data-aos="fade-right">
      <TextBig>
          + 3000 itens em estoque
        </TextBig>
      </Container>


    </StyledAboutSection>
  )

}
export {AboutSection}
export default AboutSection;