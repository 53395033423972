import React from 'react'

import {
  BrandSection, 
  Navbar, 
  AboutSection,
  ProductsSection,
  ContactSection,
  Footer,
} from '../components'

const Home = () => {
  return (
    <>
      <Navbar/>
      <BrandSection/>
      <AboutSection/>
      <ProductsSection/>
      <ContactSection/>
      <Footer/>
    </>
  )
}

export { Home }
export default Home