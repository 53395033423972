import React from "react";
import styled from "styled-components";

import WhatsLogo from '../assets/images/logo_whats.png';
import Phone from '../assets/images/phone.png';

const StyledContactSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 110%;
  background-color: #eeeeee;
  color: #215bea;
  padding: 0 64px;

  @media only screen and (max-width: 600px) {
    height: 100%;
    padding: 0 16px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0;
  text-align: justify;
`

const Subcontainer = styled.div`
  margin: 0 8px;

  > iframe {
    border-radius: 24px;
    border 2px solid #215bea;
  }

`

const SectionTitle = styled.h2`
  font: 48px 'Mont Bold';

  @media only screen and (max-width: 600px) {
    font-size: 38px;
    text-align: center;
  }
`

const Text = styled.p`
  font-size: 18px;
  text-align: center;
`

const WhatsappCard = styled.a`
  width: 320px; 
  height: 460px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #215bea;
  color: #fefefe;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms !important;

  :hover {
    background-color: #2d2c2b;
    color: #fefefe;
  }
`

const TelefoneCard = styled.a`
  width: 320px; 
  height: 460px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #2d2c2b;
  color: #fefefe;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms !important;

  :hover {
    background-color: #215bea;
    color: #fefefe;
  }
`

function ContactSection () {

  return (
    <StyledContactSection id="contato">

      <SectionTitle>Fale conosco</SectionTitle>
      <Container>

        <Subcontainer data-aos="fade-up">
          <Text>Instagram</Text>
          <iframe id="frame" width="320" height="460" src="https://www.instagram.com/p/CmMrmrDOfGn/embed" frameborder="0"></iframe>
        </Subcontainer>

        <Subcontainer data-aos="fade-down">
          <Text>Whatsapp</Text>
          <WhatsappCard 
            href="https://api.whatsapp.com/send?phone=5524981383483" 
            target='_blank'
          >
            <Container>
              <img src={WhatsLogo} alt="logo do whatsapp" width="105px" height="105px"/>
            </Container>
            <Container>
              <Text>Entre em contato pelo whatsapp e garanta os melhores preços.</Text>
            </Container>
          </WhatsappCard>
        </Subcontainer>

        <Subcontainer data-aos="fade-up">
          <Text>Telefone</Text>
          <TelefoneCard 
            href="tel:+5524992980146" 
          >
            <Container>
              <img src={Phone} alt="logo de telefone" width="105px" height="105px"/>

            </Container>
            <Container>
              <Text>Nos ligue e vamos conversar.</Text>
            </Container>
          </TelefoneCard>
        </Subcontainer>
        
      </Container>

    </StyledContactSection>
  )

}

export { ContactSection };
export default ContactSection;