import React from "react";
import styled from "styled-components";

import DAVS from '../assets/images/davs.png';


const StyledFooter = styled.footer`
  background-color: #2d2b2c;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fefefe;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
`

const Text = styled.p`
  font-size: 18px;
`

const TextDavs = styled.a`
  font-size: 18px;
  color: #686567;
  cursor: pointer;
  text-decoration: none;
  display: flex:
  align-items: center;
  justify-content: center;
  transition: 300ms;

  :hover {
    color: #fefefe;
  }
`


function Footer () {

  return (
    <StyledFooter>
      <Container>
        <Text>Puccini enxovais todos direitos reservados.</Text>
      </Container>
      <Container>
        <TextDavs href="https://api.whatsapp.com/send?phone=+5584998545520&text=Olá,%20gostaria%20de%20pedir%20um%20orçamento." target="__blank">Desenvolvido por <img src={DAVS} width="32px" height="32px"/></TextDavs>
        
      </Container>
    </StyledFooter>
  )
}

export {Footer}
export default Footer;