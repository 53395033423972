import React from "react";
import styled from 'styled-components'

import MainImg from '../assets/images/MainImg.png'
import MainImgMobile from '../assets/images/MainImgMobile.png'


const BrandContainer = styled.div`
  background-image: url(${MainImg});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 90vh;

  @media only screen and (max-width: 600px) {
    background-image: url(${MainImgMobile});
    height: 50vh;
  }
`

function BrandSection(){

  return (
    <a href="/" id='home'>
      <BrandContainer/>
    </a>
  )
  
}

export {BrandSection}
export default BrandSection